import React from "react";

// reactstrap components
import {
    // Badge,
    // Button,
    // Card,
    // CardBody,
    // CardImg,
    // CardHeader,
    // FormGroup,
    // Input,
    // InputGroupAddon,
    // InputGroupText,
    // InputGroup,
    Container,
    Row,
    Col
  } from "reactstrap";

  // core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
// import CardsFooter from "components/Footers/CardsFooter.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Developing extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.main.scrollTop = 0;
    }
    render() {
        return (
          <>
            <DemoNavbar />
            <main ref="main">
              <section className="section section-shaped section-lg padding-bottom-null">
                <div className="shape shape-style-1 shape-default">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                <Container className="pt-lg-7">
                  <Row className="justify-content-center">
                    <Col lg="5">
                      {/* <Card className="shadow border-0">
                        <CardHeader className="bg-white pb-5 develop-background padding-50"> */}
                          {/* <div className="text-muted text-center mb-3">
                            <h5 className='text-yellow-light text-shadow'>Разработка программного обеспечения проводится в несколько этапов</h5>
                          </div> */}
                          {/* <Col md="10"> */}
                            <img
                                alt="developing"
                                className="width-32 min-height-develop"
                                src={require("assets/img/brand/piano.gif")}
                            />
                          {/* </Col> */}
                        {/* </CardHeader> */}
                        {/* <CardBody className="px-lg-5 py-lg-5 background-white padding-bottom-null"> */}
                        <small className="text-yellow">25.10.20</small>
                          <div className="mb-4">
                            {/* 04.07.20 */}
                            <small className="text-white">Сейчас идёт работа над обучением чат-бота в Телеграме (канал Кубота)</small>
                          </div>
                        <hr/>
                        <small className="text-yellow">14.07.20</small>
                          <div className="mb-4">
                            {/* 04.07.20 */}
                            <small className="text-white">Документация Кубота</small>
                          </div>
                        <hr/>
                        <small className="text-yellow">05.07.20</small>
                          <div className="mb-4">
                            {/* 04.07.20 */}
                            <small className="text-white">Автоматическая оценка вопросов и целей аватаров</small>
                          </div>
                        <hr/>
                        {/* <small className="text-yellow">30.06.20</small> */}
                          <div className="mb-4">
                            <small className="text-white">...разработка программного обеспечения проводится в несколько этапов</small>
                          </div>
                        {/* </CardBody>
                      </Card> */}
                    </Col>
                  </Row>
                </Container>
              </section>
            </main>
            <SimpleFooter />
          </>
        );
      }
    }

export default Developing;

