/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="6">
                <h4 className=" mb-0 font-weight-light">
                  Новейшие технологии
                </h4>
                {/* <br/>
                <h4 className=" text-primary font-weight-light mb-2">
                  СПАСИБО, ЧТО ВЫ С НАМИ!
                </h4> */}
                
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="6">

              <Button
                  className="btn-icon-only rounded-circle"
                  color="white"
                  href="https://flutter.dev/"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    {/* <i className="fa fa-dribbble" /> */}
                    <img
                        className='icon-tech-img'
                        alt="Flutter, Dart"
                        src={require("assets/img/icons/tech/flutter-logo.jpg")}
                      />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip829810202">
                  Flutter
                </UncontrolledTooltip>

                <Button
                  className="btn-icon-only rounded-circle"
                  // color="twitter"
                  color="white"
                  href="https://ru.reactjs.org/"
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    {/* <i className="fa fa-twitter" /> */}
                    <img
                        className='icon-tech-img'
                        alt="React"
                        src={require("assets/img/icons/tech/react.jpg")}
                      />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip475038074">
                  React
                </UncontrolledTooltip>

                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  // color="facebook"
                  color="white"
                  href="https://firebase.google.com/"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    {/* <i className="fa fa-facebook-square" /> */}
                    <img
                        className='icon-tech-img'
                        alt="Firebase cloud"
                        src={require("assets/img/icons/tech/firebase.png")}
                      />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Firebase Cloud
                </UncontrolledTooltip>
                
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="white"
                  href="https://redux.js.org/"
                  id="tooltip495507257"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    {/* <i className="fab fa-js-square" /> */}
                    <img
                        className='icon-tech-img'
                        alt="Redux"
                        src={require("assets/img/icons/tech/redux.png")}
                      />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507257">
                  Redux
                </UncontrolledTooltip>

                {/* <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="white"
                  href="https://bootstrap-4.ru/"
                  id="tooltip495507258"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <img
                        className='icon-tech-img'
                        alt="Bootstrap 4"
                        src={require("assets/img/icons/tech/bootstrap-stack.png")}
                      />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507258">
                  Bootstrap 4, SCSS
                </UncontrolledTooltip> */}

                {/* <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="white"
                  href="https://builtin.com/blockchain"
                  id="tooltip495507260"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <img
                        className='icon-tech-img'
                        alt="Blockchain"
                        src={require("assets/img/icons/tech/blockchain.jpg")}
                      />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507260">
                  Blockchain
                </UncontrolledTooltip> */}

                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="white"
                  href="https://www.ibm.com/quantum-computing/"
                  id="tooltip495507259"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    {/* <i className="fab fa-js-square" /> */}
                    <img
                        className='icon-tech-img'
                        alt="IBM Quantum backend"
                        src={require("assets/img/icons/tech/ibm.png")}
                      />
                  </span>
                </Button>
                <UncontrolledTooltip delay={0} target="tooltip495507259">
                  IBM Quantum Backend
                </UncontrolledTooltip>

              </Col>
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between"> 
              <Col md="6">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    // href="https://qanb.xyz"
                    href="https://qanb.xyz"
                    target="_blank">
                    Rosticom
                  </a>
                  
                </div>
              </Col>
              <Col md="6">
                <Nav className=" nav-footer justify-content-end">
                  {/* <NavItem>
                    <NavLink
                      href="https://www.creative-tim.com?ref=adsr-footer"
                      target="_blank"
                    >
                      Rosticom
                    </NavLink>
                  </NavItem> */}
                  <NavItem>
                    <NavLink
                      href="http://wp-homework.h1n.ru"
                      target="_blank"
                    >
                      Обучение
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="http://omega.zzz.com.ua"
                      target="_blank"
                    >
                      Исследования
                    </NavLink>
                  </NavItem>
                  {/* <NavItem>
                    <NavLink
                      href="https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md"
                      target="_blank"
                    >
                      Студия
                    </NavLink>
                  </NavItem> */}
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
