/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/font-awesome/css/font-awesome.min.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

// import Index from "views/Index.js";
import Landing from "views/examples/Landing.js";
import Login from "views/examples/Login.js";
import Profile from "views/examples/Profile.js";
import Register from "views/examples/Register.js";
import Developing from "views/examples/Developing.js";
import News from "views/examples/News.js";

import * as firebase from 'firebase';
// window.$uid = '';

const firebaseConfig = {
  apiKey: "AIzaSyAmwCW27YAm4kzSOR_aP_qlvkBYeAJIiZg",
  authDomain: "qantybot.firebaseapp.com",
  databaseURL: "https://qantybot.firebaseio.com",
  projectId: "qantybot",
  storageBucket: "qantybot.appspot.com",
  messagingSenderId: "502917269498",
  appId: "1:502917269498:web:8e07a2fd86f48995b9c643",
  measurementId: "G-MKJ8SXYQ4B"
};

var countryCode='';
var countryName='';
var resultIp='';
var city='';
var region='';
var latitude='';
var longitude='';

fetch("https://freegeoip.app/json/")
.then(res => res.json())
.then(
  (result) => {
    localStorage.setItem('r.qantb.com.country_code', result.country_code);
    localStorage.setItem('r.qantb.com.country_name', result.country_name);
    localStorage.setItem('r.qantb.com.city', result.city);
    countryCode=result.country_code;
    countryName=result.country_name;
    resultIp=result.ip;
    city=result.city;
    region=result.region_name;
    latitude=result.latitude;
    longitude=result.longitude;

    // if (city) {
    //   let botUrl="https://api.telegram.org/bot1395071186:AAFp0IfrAfoSn53G1Dk5FSlWiP2bajTmc3o/sendMessage?chat_id=-1001410514694&text=Ку "+city;
    //   fetch(botUrl)
    //     .then(res => res.json())
    //     .then(
    //       (result) => {
    //         console.log('chat bot result: ', result);
    //       },
    //       (error) => {
    //         console.log('chat bot error: ', error);
    //       }
    //     )
    // }
  },
  // Примечание: важно обрабатывать ошибки именно здесь, а не в блоке catch(),
  // чтобы не перехватывать исключения из ошибок в самих компонентах.
  (error) => {
    console.log('Geo failed...')
  }
)
 
var params = window
.location
.search
.replace('?','')
.split('&')
.reduce(
    function(p,e){
        var a = e.split('=');
        p[decodeURIComponent(a[0])] = decodeURIComponent(a[1]);
        return p;
    },
    {}
);

var desire_1 = '';
var id_com = '';

// console.log('User name: ', userName);
if (params['n']) { var userName = params['n']; localStorage.setItem('r.qantb.com.user_name', userName); }
if (params['p']) { var photoUrl = params['p']; localStorage.setItem('r.qantb.com.photo_url', photoUrl); }
if (params['q']) { var questions = params['q']; localStorage.setItem('r.qantb.com.questions', questions); }
if (params['r']) { var rating = params['r']; localStorage.setItem('r.qantb.com.rate_overall', rating); }
if (params['d']) { desire_1 = params['d']; localStorage.setItem('r.qantb.com.desire_1', desire_1); }
if (params['u']) { id_com = params['u']; localStorage.setItem('r.qantb.com.id_com', id_com); }

firebase.initializeApp(firebaseConfig);
firebase.auth().signInAnonymously().catch(function(error) {
  // Handle Errors here.
  var errorCode = error.code;
  var errorMessage = error.message;
  console.log(errorCode);
  console.log(errorMessage);
  // ...
});
firebase.auth().onAuthStateChanged(function(user) {
  if (user) {
    // User is signed in.
    // var isAnonymous = user.isAnonymous;
    const userId = user.uid;
    const deviceId = user.metadata.ip;
    // this.context.userId = userId;
    localStorage.setItem('r.qantb.com.uid-anonymous', userId);
    // localStorage.setItem('r.qantb.com.device_id', deviceId);
    const db = firebase.database();
    // window.$uid = userId;
    // var updates = {}
    // updates['/anonymous/'+uid+'/']={uid: uid}
    // db.ref().update(updates);
    var doingText = localStorage.getItem('r.qantb.com.doing_role');
    var view = localStorage.getItem('r.qantb.com.view');
    var message = localStorage.getItem('r.qantb.com.message');
    if (!doingText) { doingText = ''};
    if (!view) {view='0'};
    if (!message) {message='0'};

    if (id_com) {
      db.ref('user/' + id_com + '/profile').set({
        username: userName,
        rating: rating,
        profile_picture: photoUrl,
        desire_1: desire_1
      }, function(error) {
        if (error) {
          // The write failed...
        } else {
          // Data saved successfully! 
        }
      });

      console.log('hello!!!'); 

      db.ref('user/' + id_com + '/settings').set({
        job: doingText,
        view: view,
        message: message
      });

      if (countryCode) {
        db.ref('user/' + id_com + '/geo').set({
          country_code: countryCode,
          country_name: countryName,
          result_ip: resultIp,
          city: city,
          region_name: region,
          latitude: latitude,
          longitude: longitude
        }).catch(function(error) {
          // Handle Errors here.
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorCode);
          console.log(errorMessage);
          // ...
        });;
      }
    } else { // if anonymously

      db.ref('anonymous/' + userId + '/settings').set({
        job: doingText,
        view: view,
        message: message
      });

      if (countryCode) {
        db.ref('anonymous/' + userId + '/geo').set({
          country_code: countryCode,
          country_name: countryName,
          result_ip: resultIp,
          city: city,
          region_name: region,
          latitude: latitude,
          longitude: longitude
        });
      }
    }

   

 
    // if (doingText) {
      
    // }

  } else {
    console.log('User is signed out');
  }
});

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* <Route path="/" exact render={props => <Index {...props} />} /> */}
      <Route path="/" exact render={props => <Profile {...props} />} />
      <Route
        path="/landing-page"
        exact
        render={props => <Landing {...props} />}
      />
      <Route path="/login-page" exact render={props => <Login {...props} />} />
      <Route
        path="/profile-page"
        exact
        render={props => <Profile {...props} />}
      />
      <Route
        path="/register-page"
        exact
        render={props => <Register {...props} />}
      />
      <Route
        path="/developing"
        exact
        render={props => <Developing {...props} />}
      />
      <Route
        path="/news"
        exact
        render={props => <News {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root"),
);
