
import React from "react";

import {
  Badge,
  Button,
  Card,

  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
// import CardsFooter from "components/Footers/CardsFooter.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

class Landing extends React.Component {
  // const nameLocalStorage;
  state = {};
  
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

  }
  render() {
    var userName = localStorage.getItem('r.qantb.com.user_name');
    var photoUrl = localStorage.getItem('r.qantb.com.photo_url');
    var photoUrlLength = 0;
    if (photoUrl) ( photoUrlLength=photoUrl.length);
    if (!photoUrl || photoUrlLength===2 || photoUrl==='assets/load.png') { photoUrl=null };
    if (!userName) { userName='Ростислав Нарижняк' };
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-250">
              <div className="shape shape-style-1 bg-gradient-teal">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
            
              </div>
              <Container>
              <Row className="row-grid align-items-center">
                <Col className="order-md-2" md="6">
                <a href='https://play.google.com/store/apps/details?id=com.rosticom.qantb' target='_blank' rel="noopener noreferrer">
                  <img
                    alt="google play market"
                    className="img-fluid floating border-radius min-height400"
                    src={require("assets/img/theme/promo-6.png")}
                  />
                </a>  
                </Col>
                <Col className="order-md-1 text-white" md="6">
                  <div className="pr-md-5">
                    {/* <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="ni ni-settings-gear-65" />
                    </div> */}
                    <h3 className="text-yellow">Пуск Кубота</h3>
                     <p> Для запуска квантового бота откройте сайт  
                      <a href='https://qantb.com' target='_blank' rel="noopener noreferrer" className="text-white"> qantb.com</a>,
                      или загрузите приложение из <a href='https://play.google.com/store/apps/details?id=com.rosticom.qantb' target='_blank' rel="noopener noreferrer" className="text-white">
                      Google Play Market</a> (работает без задержек 120 кадров в секунду). 
                     </p>
                     <p> Зарегистрируйтесь через Google аккаунт, желательно загрузить 
                       изображение или анимацию для аватара. 
                     </p> 
                     <p> Перемещайте аватар вправо для подтверждения или влево
                      для отмены текущего шага. Следуйте подсказкам, которые появляются на каждом этапе,
                      где требуется ваше действие.
                     </p>
                     <p> Определите свою цель и вопрос, который поможет достичь этой цели.
                     </p>
                     <p>
                      В возможности квантового бота входит поиск лучших ответов на ваш вопрос.
                      Нет никаких ограничений по сложности вашего вопроса. На этот вопрос смогут ответить
                      аватары Кубота и от этого ответа будет зависеть их рейтинг.
                     </p>
                     <p>Возможности квантового бота зависят от рейтинга аватаров, то есть
                       от ваших действий и действий других пользователей.
                     </p>
                   
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col md='auto'>
                  <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-cloud-download-95 text-yellow-light" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0 text-black">
                              Скачайте <a href='https://play.google.com/store/apps/details?id=com.rosticom.qantb' target='_blank' rel="noopener noreferrer" className="text-black-link">
                              приложение</a> или откройте <a href='https://qantb.com' target='_blank' rel="noopener noreferrer" className="text-black-link">qantb.com</a>
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-like-2 text-yellow-light" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0 text-black">Определите цель и сформулируйте вопрос</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="ni ni-satisfied text-yellow-light" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0 text-black">
                              Проверяйте возможности раз в неделю
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                </Col>
              </Row>
            </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          {/* <section className="section section-lg pt-lg-0 mt--200">
          </section> */}
          {/* <section className="section section-lg">
          </section> */}
  
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Наши друзья</h2>
                  <p className="lead text-muted">
                    Нам посчастливилось работать
                    с необыкновенными людьми в важных сферах
                    и супер-проектах, 
                    среди которых развитие и мотивация,
                    умный дом, виртуальные игры и политические платформы
                  </p>
                </Col>
              </Row>
              <Row>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="Avatar"
                      className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                      src={photoUrl==null ? require("assets/img/theme/ross-small.jpg") : photoUrl}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        {/* <span className="d-block mb-1">Ваше Имя</span> */}
                        <span className="d-block mb-1">{userName!=='Sun' ? userName : 'Rostyslav Naryzhniak'}</span>
                        <a href='https://qantb.com'><small className="h6 text-muted">qantb.com</small></a>
                      </h5>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <a href='https://www.newsforright.com' target='_blank' rel="noopener noreferrer">
                      <img
                        alt="newsforright.com"
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/theme/friend-margarita.jpg")}
                        style={{ width: "200px" }}
                      />
                    </a>
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Маргарита Овчаренко</span>
                        <a href='https://www.newsforright.com' target='_blank' rel="noopener noreferrer">
                          <small className="h6 text-muted">
                            newsforright.com
                          </small>
                        </a>
                      </h5>
                      <div className="mt-3">
                      </div>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <a href='https://web.telegram.org/#/im?p=@SaakashviliM' target='_blank' rel="noopener noreferrer">
                      <img
                        alt="Кибер-стрит"
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/theme/friend-mihail.jpg")}
                        style={{ width: "200px" }}
                      />
                    </a>
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Михаил Саакашвили</span>
                        <a href='https://cystr.com' target='_blank' rel="noopener noreferrer"><small className="h6 text-muted">cystr.com</small></a>
                      </h5>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <a href='https://www.instagram.com/valeria_lukyanova21/' target='_blank' rel="noopener noreferrer">
                      <img
                        alt="Валерия Лукьянова"
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/theme/friend-amatue.jpg")}
                        style={{ width: "200px" }}
                      />
                    </a>
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Валерия Лукьянова</span>
                        <a href='http://amatue-neo.com' target='_blank' rel="noopener noreferrer"><small className="h6 text-muted">amatue-neo.com</small></a>
                      </h5>
                      <div className="mt-3">
                        {/* <Button
                          className="btn-icon-only rounded-circle"
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="warning"
                          href="https://www.facebook.com/valeriyalukyanova"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-facebook" />
                        </Button>
                        <Button
                          className="btn-icon-only rounded-circle ml-1"
                          color="success"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button> */}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <Card className="bg-gradient-warning shadow-lg border-0 border-radius">
                <div className="p-5">
                  <Row className="align-items-center">
                    <Col lg="8">
                      <h3 className="text-white">
                        Креативный кодинг со вкусом.
                      </h3>
                      <p className="lead text-white mt-3">
                        Разработка софта для известных компаний, 
                        лучшие практики и понимание новых идей
                      </p>
                    </Col>
                    <Col className="ml-lg-auto" lg="3">
                      <Button
                        block
                        className="btn-white"
                        color="default"
                        href="https://www.real-manager.club/ru"
                        size="lg"
                        target="_blank"
                      >
                        Например
                      </Button>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          {/* <section className="section section-lg bg-gradient-default shabloni-background"> */}
          <section className="section section-lg shabloni-background">
            {/* <Container className="pt-lg pb-300">
          
            </Container> */}
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
            <Row className="justify-content-md-center text-center">
              <Col className="height-100 padding-top-35">
                <h5 className='text-gray'>разрыв</h5>
              </Col>
                <Col className="padding-0">
                    <img
                      className='rounded-circle height-100'
                      alt="animation"
                      src={require("assets/img/brand/yin-yang.gif")}
                    />
                </Col>
              <Col className="height-100 padding-top-35">
                <h5 className='text-gray'>шаблонов</h5>
              </Col>
            </Row>
          </section>
          {/* <section className="section section-lg pt-lg-0 section-contact-us">
      
          </section> */}
          
          <Download />
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
