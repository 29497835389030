/*!

=========================================================
* Qantbot - v0.1.0
=========================================================

* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by rost.i.com

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  DropdownMenu,
  // DropdownItem,
  DropdownToggle,
  UncontrolledDropdown,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out"
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: ""
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
                <img className='img-quant'
                  alt="Квантовый бот"
                  src={require("assets/img/brand/argon-react-white.png")}
                />
                <h1 className='h1_style'>Кубот</h1>
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="10">
                      <Link to="/">
                        <img
                          alt="Квантовый бот"
                          src={require("assets/img/brand/robot1.gif")}
                        />
                        <h1 className='h1_style_black'>Кубот</h1>
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="2">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-atom d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Аннотация</span>
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-menu-xl show">
                      <div className="dropdown-menu-inner">
                        <Media
                          className="d-flex align-items-center"
                          // href="https://qantb.com"
                          to="/landing-page" tag={Link}
                          // target="_blank"
                        >
                          <div className="icon icon-shape bg-gradient-primary rounded-circle text-white">
                            <i className="ni ni-spaceship" />
                          </div>
                          <Media body className="ml-3">
                            <h6 className="heading text-primary mb-md-1">
                              Пуск
                            </h6>
                            <p className="description d-none d-md-inline-block mb-0">
                              {/* Learn how to use Argon compiling Scss, change
                              brand colors and more. */}
                              Как пользоваться квантовым ботом.
                            </p>
                          </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          // href="https://qantb.com"
                          // target="_blank"
                          to="/developing" tag={Link}>
                          <div className="icon icon-shape bg-gradient-success rounded-circle text-white">
                            <i className="ni ni-like-2" />
                          </div>
                        <Media body className="ml-3">
                          <h6 className="heading text-primary mb-md-1">
                            Развитие
                          </h6>
                          <p className="description d-none d-md-inline-block mb-0">
                            {/* Learn more about colors, typography, icons and the
                            grid system we used for Argon. */}
                            Цели, которые ставим в ближайшее время.
                          </p>
                        </Media>
                        </Media>
                        <Media
                          className="d-flex align-items-center"
                          // href="https://qantb.com"
                          // target="_blank"
                          to="/news" tag={Link}
                        >
                          <div className="icon icon-shape bg-gradient-warning rounded-circle text-white">
                            <i className="ni ni-atom" />
                          </div>
                          <Media body className="ml-3">
                            <h5 className="heading text-warning mb-md-1">
                              Новости технологии
                            </h5>
                            <p className="description d-none d-md-inline-block mb-0">
                              Квантовые технологии и КуБот.
                            </p>
                          </Media>
                        </Media>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  {/* <UncontrolledDropdown nav>
                    <DropdownToggle nav>
                      <i className="ni ni-collection d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Examples</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem to="/landing-page" tag={Link}>
                        Landing
                      </DropdownItem>
                      <DropdownItem to="/profile-page" tag={Link}>
                        Profile
                      </DropdownItem>
                      <DropdownItem to="/login-page" tag={Link}>
                        Login
                      </DropdownItem>
                      <DropdownItem to="/register-page" tag={Link}>
                        Register
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.facebook.com/Quantum-bot-110709050689541"
                      id="tooltip333589073"
                      target="_blank"
                    >
                      <i className="fa fa-facebook-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Фейсбук
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589073">
                      Фейсбук
                    </UncontrolledTooltip>
                  </NavItem>
                  
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://t.me/qantb"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fa fa-telegram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Кубот канал
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip333589074">
                      Кубот канал
                    </UncontrolledTooltip>
                  </NavItem>
                  
                  {/* <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.instagram.com/creativetimofficial"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Instagram
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip356693867">
                      Follow us on Instagram
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://twitter.com/creativetim"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fa fa-twitter-square" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Twitter
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip184698705">
                      Follow us on Twitter
                    </UncontrolledTooltip>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://github.com/creativetimofficial/argon-design-system-react"
                      id="tooltip112445449"
                      target="_blank"
                    >
                      <i className="fa fa-github" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Github
                      </span>
                    </NavLink>
                    <UncontrolledTooltip delay={0} target="tooltip112445449">
                      Star us on Github
                    </UncontrolledTooltip>
                  </NavItem> */}
                  <NavItem className="d-none d-lg-block ml-lg-4">
                    <Button
                      alt="Get it on Google Play"
                      className="btn-neutral btn-icon padding-none google_play_button"
                      color="default"
                      href="https://play.google.com/store/apps/details?id=com.rosticom.qantb"
                      target="_blank"
                    >
                      <img
                        className='google_play_download'
                        alt="Google Play Download"
                        src={require("assets/img/brand/google-play-badge5.png")}
                      />
                    </Button>
                  </NavItem>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
