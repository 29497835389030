/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Button, Container, Row, Col, UncontrolledTooltip } from "reactstrap";

class Download extends React.Component {
  render() {
    return (
      <>
        <section className="section section-lg">
          <Container>
            <Row className="row-grid justify-content-center">
              <Col className="text-center" lg="8">
                <h2 className="display-3">
                  Вам нравятся новые технологии{" "}
                  <span className="text-success">
                    так же как и нам?
                  </span>
                </h2>
                <p className="lead">
                  Нажмите на эту кнопку
                  чтобы перейти в магазин Google Play и загрузить Кубот. 
                  Воспользуйтесь новейшими
                  технологиями IT, квантовой механики и нейронета!
                </p>
                <div className="btn-wrapper">
                  {/* <Button
                    className="mb-3 mb-sm-0"
                    color="primary"
                    href="https://www.creative-tim.com/product/argon-design-system-react?ref=adsr-landing-page"
                  >
                    Download React
                  </Button> */}
                  <Button
                      alt="Get it on Google Play"
                      className="btn-neutral btn-icon padding-none google_play_button"
                      color="default"
                      href="https://play.google.com/store/apps/details?id=com.rosticom.qantb"
                      target="_blank"
                    >
                      <img
                        className='google_play_download'
                        alt="Google Play Download"
                        src={require("assets/img/brand/google-play-badge6.png")}
                      />
                    </Button>
                </div>
                <div className="text-center">
                  <h4 className="display-4 mb-5 mt-5">
                    Кроме того, у нас есть опыт работы:
                  </h4>
                  <Row className="justify-content-center">
                    <Col lg="2" xs="4">
                      <a
                        href="https://ionicframework.com/"
                        id="tooltip265846671"
                        target="_blank"
                      >
                        <img
                          alt="develop ionic"
                          className="img-fluid rounded-circle"
                          src={require("assets/img/brand/ionic.jpg")}
                          
                        />
                      </a>
                      <UncontrolledTooltip delay={0} target="tooltip265846671">
                      Ionic Framework 5 - Design. Animation. Performance.
                      </UncontrolledTooltip>
                    </Col>
                    <Col lg="2" xs="4">
                      <a
                        href="https://bootstrap-4.ru/"
                        id="tooltip255035741"
                        target="_blank"
                      >
                        <img
                          alt="develop Bootstrap 4"
                          className="img-fluid rounded-circle"
                          src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/bootstrap.jpg"
                        />
                      </a>
                      <UncontrolledTooltip delay={0} target="tooltip255035741">
                        Bootstrap 4 - Most popular front-end component library
                      </UncontrolledTooltip>
                    </Col>
                    <Col lg="2" xs="4">
                      <a
                        href="https://angular.io/"
                        id="tooltip233150499"
                        target="_blank"
                      >
                        <img
                          alt="develop angular"
                          className="img-fluid rounded-circle"
                          src="https://s3.amazonaws.com/creativetim_bucket/tim_static_images/presentation-page/angular.jpg"
                        />
                      </a>
                      <UncontrolledTooltip delay={0} target="tooltip233150499">
                        Angular - One framework. Mobile & Desktop
                      </UncontrolledTooltip>
                    </Col>
                    <Col lg="2" xs="4">
                      <a
                        href="https://sass-scss.ru/guide/"
                        id="tooltip308866163"
                        target="_blank"
                      >
                        <img
                          alt="develop scss"
                          className="img-fluid"
                          src={require("assets/img/brand/sass.png")}
                        />
                      </a>
                      <UncontrolledTooltip delay={0} target="tooltip308866163">
                        Sass (Syntactically Awesome Stylesheets)
                      </UncontrolledTooltip>
                    </Col>
                    <Col lg="2" xs="4">
                      <a
                        href="https://nodejs.org/en/"
                        id="tooltip76119384"
                        target="_blank"
                      >
                        <img
                          alt="develop java script"
                          className="img-fluid"
                          src={require("assets/img/brand/js.png")}
                        />
                      </a>
                      <UncontrolledTooltip delay={0} target="tooltip76119384">
                        Node.js® is a JavaScript runtime built.
                      </UncontrolledTooltip>
                    </Col>
                    {/* <Col lg="2" xs="4">
                      <a
                        href="https://wordpress.org/"
                        id="tooltip646643508"
                        target="_blank"
                      >
                        <img
                          alt="wordpress"
                          className="img-fluid"
                          src={require("assets/img/brand/word-press.png")}
                        />
                      </a>
                      <UncontrolledTooltip delay={0} target="tooltip646643508">
                        WordPress to create a beautiful website, blog, or app.
                      </UncontrolledTooltip>
                    </Col> */}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Download;
