

import React from "react";


import { Button, Card, Container, Modal, Row, Col,
  FormGroup,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";
import ReactTooltip from 'react-tooltip';


class Profile extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    var countryCode = localStorage.getItem('r.qantb.com.country_code');
    var countryName = localStorage.getItem('r.qantb.com.country_name');
    var city = localStorage.getItem('r.qantb.com.city');
    // var doingTextChange = localStorage.getItem('r.qantb.com.doing_role');
    if (countryCode) {
      this.setState({
        isIPLoaded: true,
        countryCode: countryCode,
        countryName: countryName,
        countryFlagSrc: "https://www.countryflags.io/"+countryCode+"/flat/24.png"
      });
    }
  }
  state = {};
  toggleModal = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  toggleModal2 = state => {
    this.setState({
      [state]: !this.state[state]
    });
  };
  countryTown = state => {
    this.setState({
      [state]: !this.state[state]
    });
  }
  blurChangeFun(value) {
      var city=localStorage.getItem('r.qantb.com.city');
      console.log('value: ', value);
      // console.log('this.doingText: ', this.doingTextChange);

      // if (value !== this.doingTextChange) {
        let ucValue=value.charAt(0).toUpperCase() + value.slice(1);
        if (ucValue.trim().length!==0) {
          let botUrl="https://api.telegram.org/bot1395071186:AAFp0IfrAfoSn53G1Dk5FSlWiP2bajTmc3o/sendMessage?chat_id=-1001410514694&text=Определение сферы деятельности: "+ucValue+", "+city;
          fetch(botUrl)
            .then(res => res.json())
            .then(
              (result) => {
                console.log('chat bot result: ', result);
              },
              (error) => {
                console.log('chat bot error: ', error);
              }
            )
        }
      
      // }
    // }

  };
  doingChangeFun(value) {
    let ucValue=value.charAt(0).toUpperCase() + value.slice(1);
    this.setState({
      changeText: ucValue
    });
    localStorage.setItem('r.qantb.com.doing_role', ucValue);
    // this.setDbDoing(value);
  }

  chatBotMessage(message, city) {
    let botUrl="https://api.telegram.org/bot1395071186:AAFp0IfrAfoSn53G1Dk5FSlWiP2bajTmc3o/sendMessage?chat_id=-1001410514694&text="+message+", "+city;
    fetch(botUrl)
      .then(res => res.json())
      .then(
        (result) => {
          console.log('chat bot result: ', result);
        },
        (error) => {
          console.log('chat bot error: ', error);
        }
      )
  }
  
  render() {
    var doingText = localStorage.getItem('r.qantb.com.doing_role');
    if (!doingText) { doingText = 'Род деятельности'; }
    var userName = localStorage.getItem('r.qantb.com.user_name');
    var photoUrl = localStorage.getItem('r.qantb.com.photo_url');
    var rating = localStorage.getItem('r.qantb.com.rate_overall');
    var desire1 = localStorage.getItem('r.qantb.com.desire_1');
    var city = localStorage.getItem('r.qantb.com.city');
    var userCom = localStorage.getItem('r.qantb.com.user_com');

    if (desire1 === null) { desire1 = 'Определите цель на qantb.com'; } 
    if (desire1 !== null && doingText === 'Род деятельности') { desire1 = 'Определите род деятельности'; }

    if (!userName) { userName='Имя Фамилия' };

    var photoUrlLength = 0;
    if (photoUrl) ( photoUrlLength=photoUrl.length);
    if (!photoUrl || photoUrlLength===2 || photoUrl==='assets/load.png') { photoUrl=null };
    if (rating === 'null') { rating = 0 };
    if (userName==='Sun') { userName='Имя Фамилия' };
    return (
      <>
        <DemoNavbar />
        <main className="profile-page" ref="main">
          <section className="section-profile-cover section-shaped my-0">
            {/* Circles background */}
            <div className="shape shape-style-1 shape-default bg-gradient-info alpha-4">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Link to="/landing-page" data-tip data-for='happyFace'>
                  <div className="mt-5 py-5 prof-background"></div>
            </Link>
            <ReactTooltip id='happyFace' type='info'>
              <span>{desire1}</span>
            </ReactTooltip>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          <section className="section">
            <Container>
              <Card className="card-profile shadow mt--300 border-radius-bottom">
                <div className="px-4">
                  <Row className="justify-content-center">
                    <Col className="order-lg-2" lg="3">
                      <a href='https://qantb.com' className="text-white">
                        <div className="card-profile-image">
                            <img
                              alt="Cyber-anti-bot"
                              className="rounded-circle"
                              src={photoUrl===null ? require("assets/img/theme/team-4-800x800.jpg") : photoUrl}
                            />
                        </div>
                      </a>
                    </Col>
                    <Col
                      className="order-lg-3 text-lg-right align-self-lg-center"
                      lg="4"
                    >
                      <div className="card-profile-actions py-4 mt-lg-0">
                        <Button
                          className="mr-4"
                          color="info"
                          href="#pablo"
                          onClick={() => this.toggleModal("defaultModal")}
                          size="sm"
                        >
                          Просмотр
                        </Button>
                        <Modal
                          className="modal-dialog-centered"
                          isOpen={this.state.defaultModal}
                          toggle={() => this.toggleModal("defaultModal")}
                        >
                          <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">
                              Согласие на просмотр
                            </h6>
                            <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => this.toggleModal("defaultModal")}
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <p>
                              Разрешить просмотр вашего профиля 
                              аватарам с рейтингом выше чем у вас?
                            </p>
                            <p>
                              Если да, нажмите кнопку разрешить.
                            </p>
                            <p>
                              Функция станет доступной в следующих выпусках.
                            </p>
                          </div>
                          <div className="modal-footer">
                            <Button 
                              color="primary" 
                              type="button"
                              onClick={() => { 
                                this.toggleModal2("defaultModal"); 
                                localStorage.setItem('r.qantb.com.view', 1);
                                this.chatBotMessage("Разрешён просмотр профиля", city);
                              }}>
                              Разрешить
                            </Button>
                            <Button
                              className="ml-auto"
                              color="link"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => { 
                                this.toggleModal("defaultModal"); 
                                localStorage.setItem('r.qantb.com.view', 0);
                                this.chatBotMessage("Запрет просмотра профиля", city); 
                              }}
                            >
                              Запрет
                            </Button>
                          </div>
                        </Modal>

                        <Button
                          className="float-right"
                          color="default"
                          href="#pablo"
                          onClick={() => this.toggleModal2("defaultModal2")}
                          size="sm"
                        >
                          Сообщения
                        </Button>
                        <Modal
                          className="modal-dialog-centered"
                          isOpen={this.state.defaultModal2}
                          toggle={() => this.toggleModal2("defaultModal2")}
                        >
                          <div className="modal-header">
                            <h6 className="modal-title" id="modal-title-default">
                              Согласие на сообщения
                            </h6>
                            <button
                              aria-label="Close"
                              className="close"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => this.toggleModal2("defaultModal2")}
                            >
                              <span aria-hidden={true}>×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <p>
                              Получать сообщения от 
                              других аватаров с рейтингом выше чем у вас?
                            </p>
                            <p>
                              Если да, нажмите кнопку разрешить.
                            </p>
                            <p>
                              Функция станет доступной в следующих выпусках.
                            </p>
                          </div>
                          <div className="modal-footer">
                            <Button 
                              color="primary" 
                              type="button" 
                              onClick={() => { 
                                this.toggleModal2("defaultModal2"); 
                                localStorage.setItem('r.qantb.com.message', 1);
                                this.chatBotMessage("Разрешено получение сообщений", city);
                              }}>
                              Разрешить
                            </Button>
                            <Button
                              className="ml-auto"
                              color="link"
                              data-dismiss="modal"
                              type="button"
                              onClick={() => { 
                                this.toggleModal2("defaultModal2"); 
                                localStorage.setItem('r.qantb.com.message', 0); 
                                this.chatBotMessage("Запрет получения сообщений", city);
                              }}
                            >
                              Запрет
                            </Button>
                          </div>
                        </Modal>
                      </div>
                    </Col>
                    <Col className="order-lg-1" lg="4">
                      <div className="card-profile-stats d-flex justify-content-center">
                        <div>
                          <span className="heading">0</span>
                          <span className="description">Ответы</span>
                        </div>
                        <div>
                          <span className="heading">0</span>
                          <span className="description">Реакция</span>
                        </div>
                        <div>
                          <span className="heading">{rating!==null ? rating : 0}</span>
                          <span className="description">Рейтинг</span>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <div className="text-center mt-5 name-job">
                    { userName!=='Имя Фамилия' &&
                      <h3 className='padding-bottom'>
                        {userName}
                      </h3>
                    }
                    <div id="recaptcha-container"></div>
                    <Row className="justify-content-center">
                      <Button
                        onClick={()=> {
                          console.log(this.state.countryFlagSrc);
                        }}
                        className="btn-tooltip btn-flag"
                        color="neytral"
                        id="tooltip38321129"
                        size="sm">
                        { this.state.isIPLoaded===true && this.state.countryFlagSrc!=='https://www.countryflags.io//flat/24.png' &&
                          <img alt="..." className='img-country'
                          src={this.state.countryFlagSrc}
                          /> 
                        }
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        placement="left"
                        target="tooltip38321129"
                        trigger="hover focus">
                        {this.state.countryName}
                      </UncontrolledTooltip>

                    {/* { !this.state.countryTownEdit && userName!='Имя Фамилия' &&  */}
                    { !this.state.countryTownEdit && 
                      <div className="h6 font-weight-300 cursor-pointer doing-label" 
                        onClick={() => { 
                          if (doingText==='Род деятельности') { doingText = ''; }
                          this.countryTown("countryTownEdit"); 
                          // console.log('this.state.countryTownEdit: ', this.state.countryTownEdit); 
                          this.setState({
                            changeText: doingText
                          });
                          // this.state.changeText = doingText;
                        }}>
                        <i className="ni location_pin mr-2" />
                        {doingText}
                      </div>
                    }

                    { this.state.countryTownEdit===true && 
                          <FormGroup>
                            <Input className="countryTownInputClass1"
                              maxLength="21"
                              value={this.state.changeText}
                              autoFocus={true}
                              placeholder="Род деятельности" 
                              type="text"
                              onChange={(e) => { this.doingChangeFun(e.target.value); } }
                              onBlur={(e) => { this.blurChangeFun(e.target.value); }}
                              // onBlur={(e) => { this.countryTown("countryTownEdit"); }}
                              // onFocus={e => this.setState({ CountryTownFocused: true })}
                              // onBlur={e => this.setState({ CountryTownFocused: false })} 
                              // input.classList.add('invalid');
                              // this.classList.remove('invalid');
                            />
                          </FormGroup>
                    }
                    </Row>
                  </div>
                </div>
              </Card>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Profile;


