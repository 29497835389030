import React from "react";

// reactstrap components
import {
    // Badge,
    // Button,
    // Card,
    // CardBody,
    // CardImg,
    // CardHeader,
    // FormGroup,
    // Input,
    // InputGroupAddon,
    // InputGroupText,
    // InputGroup,
    Container,
    Row,
    Col
  } from "reactstrap";

  // core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
// import CardsFooter from "components/Footers/CardsFooter.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class News extends React.Component {
    state = {};
    componentDidMount() {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.main.scrollTop = 0;
    }
    render() {
        return (
          <>
            <DemoNavbar />
            <main ref="main">
              <section className="section section-shaped section-lg padding-bottom50">
                <div className="shape shape-style-1 bg-gradient-primary">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                <Container className="pt-lg-7 padding-top33">
                  <Row className="justify-content-center">
                    <Col lg="5">
                    {/* <Card className="bg-secondary shadow border-0">
                        <CardHeader className="bg-white pb-5 padding-bottom-null news-background">
                          <div className="text-muted text-center mb-3">
                            <h2 className='text-yellow-light'></h2>
                          </div>
                          <Col md="12"> */}
                            <img
                                alt="news"
                                className="width-32 min-height-news"
                                src={require("assets/img/theme/news.gif")}
                            />
                          {/* </Col>
                        </CardHeader>
                        <CardBody className="px-lg-5 py-lg-5 padding-bottom-null 
                                  padding-top-null background-white"> */}
                          {/* 04.07.20 */}
                          {/* <div className="text-center text-muted mb-4">
                            <small className="text-yellow">Релиз с вопросами аватаров (бета 0.2.0)</small>
                          </div> */}
                          {/* <hr/> */}
                          <small className="text-yellow">25.10.20</small>
                          <div className="text-muted mb-4">
                            <small className="text-white">Перечислен донат топовому аватару, занявшему 1е место в рейтинге и указавшему род деятельности (10 евро), с дополнительной ежемесячной выплатой, начиная с 1го числа, по 10 евро</small>
                          </div>
                          <hr/>
                          <small className="text-yellow">25.10.20</small>
                          <div className="text-muted mb-4">
                            <small className="text-white">Открыт канал для работы чат-бота в Телеграме (Кубот)</small>
                          </div>
                          <hr/>
                          <small className="text-yellow">10.07.20</small>
                          <div className="text-muted mb-4">
                            <small className="text-white">Релиз с вопросами аватаров бета - 0.2.0</small>
                          </div>
                          {/* <hr/>
                          <div className="text-center text-muted mb-4">
                            <small className="text-white">Запуск документации квантового бота!</small>
                          </div> */}
                        {/* </CardBody>
                      </Card> */}
                    </Col>
                  </Row>
                </Container>
                
              </section>
            </main>
            <SimpleFooter />
          </>
        );
      }
    }

export default News;

